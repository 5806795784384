import * as React from 'react'
import PropTypes from 'prop-types'
import './styles.scss'

const Caret = ({ variant }) => {
    return (
        <span className={`c-caret ${variant ? `c-caret--${variant}` : ''}`}>
            &raquo;
        </span>
    )
}

Caret.propTypes = {
    variant: PropTypes.oneOf(['forward', 'back'])
}

Caret.defaultProps = {
    variant: 'forward'
}

export default Caret
